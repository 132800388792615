import { useState } from "react";
import Navigation from "../Navigation/Navigation";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  
  return (
    <header>
      <div className={`header__menu ${menuOpen ? "open" : ""}`}>
        <Navigation />
      </div>
    </header>
  );
};

export default Header;