import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { MAINTENANCE_MODE } from "./Constants/constants";
import ScrollToTop from "./components/ScrollToTop";
import Holding from "./pages/Holding";
import Home from "./pages/Home";
import Judges from "./pages/Judges/Judges";
import Categories from "./pages/Categories";
import Finalists from "./pages/Finalists";
import Winners from "./pages/Winners";
import Gallery from "./pages/Gallery/Gallery";
import Enter from "./pages/Enter";
import EntryContact from "./components/EntryForm/EntryContact";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Faq from "./pages/Faq/Faq";
import EntryTips from "./pages/Entry/EntryTips";
import LostPage from "./pages/404/404";
import useScrollToAnchor from './hooks/useScrollToAnchor';
import LoadingDelay from './components/Loading/LoadingDelay';
import CookieConsent from "./components/CookieConsent";

import "normalize.css";
import "./theme/base/base.scss";
import "./theme/layout/layout.scss";

// Wrap components using HOC with specified delay LoadingDelay(ImportName, Delay)

const HomeWithLoading = LoadingDelay(Home);
const JudgesWithLoading = LoadingDelay(Judges);
const CategoriesWithLoading = LoadingDelay(Categories);
const FinalistsWithLoading = LoadingDelay(Finalists);
const WinnersWithLoading = LoadingDelay(Winners);
const EnterWithLoading = LoadingDelay(Enter);
const GalleryWithLoading = LoadingDelay(Gallery);
const FaqWithLoading = LoadingDelay(Faq);
const EntryTipsWithLoading = LoadingDelay(EntryTips);
const LostPageWithLoading = LoadingDelay(LostPage);


function App() {
  useScrollToAnchor();
  return (
    <HelmetProvider>
      <ScrollToTop />
      {MAINTENANCE_MODE ? null : <Header />}
      <Routes>
        <Route exact path='/' element={MAINTENANCE_MODE ? <Holding /> : <HomeWithLoading />} />
        {MAINTENANCE_MODE && <Route path='/home' element={<div><Header /><HomeWithLoading /></div>} />}
        <Route path='/judges' element={<JudgesWithLoading />} />
        <Route path='/categories' element={<CategoriesWithLoading />} />
        <Route path='/finalists' element={<FinalistsWithLoading />} />
        <Route path='/winners' element={<WinnersWithLoading />} />
        <Route path='/enter' element={<EnterWithLoading />} />
        <Route path="/entry/:uuid" element={<EntryContact />} />
        <Route path='/gallery' element={<GalleryWithLoading />} />
        <Route path='/faq' element={<FaqWithLoading />} />
        <Route path='/entry-tips' element={<EntryTipsWithLoading />} />
        {/* 404 page */ }
        <Route path='*' element={<LostPageWithLoading />} />
      </Routes>
      {MAINTENANCE_MODE ? null : <Footer />}
      <CookieConsent />
    </HelmetProvider>
  );
  
}

export default App;
