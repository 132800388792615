import React from "react";
import styles from "./Message.module.scss";
import classNames from "classnames";

const Message = ({ type, children, className }) => {

  const messageClasses = classNames(styles.message, styles["message_" + type], className);


  return (
    <div
      className={messageClasses}
      role='status'>
      {children}
    </div>
  );
};

export default Message;
