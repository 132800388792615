import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.loading}>
      <ScaleLoader color="#d4af37" size={150} /> {/* Customize color and size as needed */}
    </div>
  );
};

export default Loading;
