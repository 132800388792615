import React, { useState } from "react";
import styles from "./Accordion.module.scss"; // Import your CSS file

function Accordion(props) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      // If the clicked item is already active, close it
      setActiveIndex(-1);
    } else {
      // Otherwise, set the clicked item as active and close any others
      setActiveIndex(index);
    }
  };

  return (
    <div className={`${props.className ? props.className : ''}  ${styles.accordion}`}>
      {props.items.map((item) => (
        <div key={`${item.id}`}>
          <h2 className={styles.accordion__title}>{item.name}</h2>
          {item.questions.map((question, index) => {
            return (
              <div key={`question-${index}`} className={styles.accordion__item}>
                <details className={styles.accordion__details} open={index === 0}>
                  <summary
                    className={styles.accordion__header}
                    onClick={() => handleAccordionClick(index)}>
                    {question.title}
                  </summary>

                  <div
                    className={`${styles.accordion__content} ${activeIndex === index ? "open" : ""}`}>
                    {question.content}
                  </div>
                </details>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
