import { Link as RouterLink, useLocation } from "react-router-dom";
import styles from "./Button.module.scss";

function Button({ to, type = "primary", className, children, onClick, filled = true }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const handleAnchorClick = (e) => {
    if (isHomePage && to && to.startsWith("/#")) {
      e.preventDefault();
      const anchor = to.split("#")[1];
      const element = document.getElementById(anchor);
      if (element) element.scrollIntoView({ behavior: "smooth" });

      // Update the URL in the address bar without adding a new history entry
      window.history.replaceState(null, null, to);
    }
  };

  // Conditionally apply the 'button_filled' class if 'filled' prop is true
  const buttonClasses = `${styles.button} ${styles[`btn_${type}`]} ${filled ? styles[`button_filled_${type}`] : styles[`button_outline_${type}`]} ${className ? className : ""}`;

  if (to) {
    return (
      <RouterLink
        to={to}
        className={buttonClasses}
        onClick={(e) => {
          handleAnchorClick(e);
          if (onClick) onClick(e);
        }}>
        {children}
      </RouterLink>
    );
  }

  return null;
}

export default Button;
