import React from 'react'
import styles from './SocialMedia.module.scss'

const SocialMediaItem = ({item}) => {

  const { name, url, icon } = item;
 
  return (
    <>
      {name && (
        <div key={name} className={styles.item}>
          {url && (
            <a href={url} target='_blank' rel="noreferrer">{icon && (<img src={icon} alt={name} />)} {name}</a>
          )}
        </div>
      )}
    </>
  )
}

export default SocialMediaItem