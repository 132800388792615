import React from "react";
import Contact from "./Contact";
import styles from './Contacts.module.scss'
export const data = [
  {
    id: 1,
    name: "Simon Turton",
    team: "Sales",
    picture: "/img/contacts/simon.jpg",
    role: "Sponsorship opportunities",
    contactNumber: "+447759 731 134",
    email: "sturton@bpl-business.com",
  },
  {
    id: 2,
    name: "Heather Miles",
    team: "Sales",
    picture: "/img/contacts/heather.jpg",
    role: "Sponsorship & Table Sales",
    contactNumber: "+447519 313 700",
    email: "hmiles@bpl-business.com",
  },
  {
    id: 3,
    name: "Rachel Seymour",
    team: "Ops",
    picture: "/img/contacts/rachel.jpg",
    role: "Event enquiries",
    contactNumber: "+447977 034 752",
    email: "rseymour@bpl-business.com",
  },
  {
    id: 4,
    name: "Jenny Norman",
    team: "Ops",
    picture: "/img/contacts/jenny.jpg",
    role: "Event enquiries",
    contactNumber: "+441234 567 891",
    email: "jnorman@bpl-business.com",
  }
];
const Contacts = () => {


  return (
    <section id="contacts">
      <div className="container">
        <h2 className={styles.title}>Contacts</h2>
        <Contact contacts={data} />
      </div>
    </section>
  );
};

export default Contacts;
