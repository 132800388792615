import styles from './Stats.module.scss';

const Stats = ({ values }) => {
  return (
    <div className={styles.statistics}>
      {values.map((stat) => (
         <div key={stat.id} className={styles.statistic}>
         {stat.value && <div className={styles.value}>{stat.value}</div>}
         {stat.name && <h3 className={styles.title}>{stat.name}</h3>}
       </div>
      ))}
    </div>
  );
}

export default Stats;

