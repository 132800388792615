import React from "react";
import EnquiryForm from "../components/EntryForm/EntryForm"
import { Helmet } from "react-helmet-async";
import { EVENT_ADDRESS, EVENT_DATE, EVENT_NAME, EVENT_VENUE } from "../Constants/constants";

const Enter = () => {
  const formattedStartDate = new Date(EVENT_DATE).toISOString(); // Ensure date is in ISO format

  return (
    <>
      <Helmet>
        <title>Register Now - {EVENT_NAME} | Comms Dealer</title>
        <meta name="description" content="Secure your spot at the Comms National Awards! Register now to participate in the premier event recognizing excellence across the communications sector." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Event",
            "name": EVENT_NAME,
            "description": "Register now and be part of the Comms National Awards, the most respected and prestigious awards in the communications channel.",
            "url": "https://www.cnawards.com/enter",
            "location": {
              "@type": "Place",
              "name": EVENT_VENUE,
              "address": EVENT_ADDRESS
            },
            "startDate": formattedStartDate,
            "endDate": formattedStartDate,
            "organizer": {
              "@type": "Organization",
              "name": "Comms Dealer",
              "url": "https://www.commsdealer.com"
            }
          })}
        </script>
      </Helmet>

      <div>
        <EnquiryForm />
      </div>
    </>
  );
};

export default Enter;
