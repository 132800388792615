import React from "react";
import CategoryTabs from "../components/Category/CategoryTabs";
import { data } from "../components/Category/CategoryData";
import PageTitle from '../components/PageTitle/PageTitle'
import { Helmet } from "react-helmet-async";
import { EVENT_ADDRESS, EVENT_DATE, EVENT_NAME, EVENT_VENUE } from "../Constants/constants";

const Categories = () => {
  const formattedStartDate = new Date(EVENT_DATE).toISOString(); // Assuming EVENT_DATE is a valid date string or object
  return (
    <>
      <Helmet>
        <title>Explore Categories to Enter | {EVENT_NAME}</title>
        <meta name="description" content="Explore the diverse categories you can enter at the Comms National Awards. Find your best match and showcase your achievements." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Event",
            "name": "Comms National Awards",
            "description": "Explore the diverse categories available at the Comms National Awards. Find the perfect category to showcase your achievements.",
            "url": "https://www.cnawards.com/categories",
            "location": {
              "@type": "Place",
              "name": EVENT_VENUE,
              "address": EVENT_ADDRESS
            },
            "startDate": formattedStartDate,
            "endDate": formattedStartDate,
            "organizer": {
              "@type": "Organization",
              "name": "Comms Dealer",
              "url": "https://www.commsdealer.com"
            }
          })}
        </script>
      </Helmet>

      <PageTitle>Categories</PageTitle>
      <CategoryTabs data={data} />
    </>

  );
};

export default Categories;
