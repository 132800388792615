import React from "react";
import Judge from "../../components/Judge/Judge";
import PageTitle from "../../components/PageTitle/PageTitle"
import {JudgeData} from './JudgeData'

const Judges = () => {
  return (
    <div>
      <PageTitle> Judges </PageTitle>
      <Judge profiles={JudgeData} />
    </div>
  );
};

export default Judges;
