import { calculateDaysLeft, EVENT_DATE_STRING } from "../../Constants/constants";

const stats = [
  {
    id: 'award_categories',
    name: 'Award categories',
    value: '32',
  },
  {
    id: 'attendees',
    name: 'Evening Attendees',
    value: '500+',
  },
  {
    id: 'years_of_recognition',
    name: 'Years of recognition',
    value: '20+',
  },
]

const dynamicStat = {
  id: 'days_left_enter',
  name: 'Days left to go',
  value: calculateDaysLeft(EVENT_DATE_STRING).toString(),
};

export const allStats = [...stats, dynamicStat];