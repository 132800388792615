import React from 'react'
import Stats, { allStats } from "../Stats";
import Button from '../Button';
import styles from './Quickstats.module.scss';

const Quickstats = () => {
  return (
    <section className={styles.quickstats}>
      <div className="container">
        <div className={styles.quickstats_inner}>
          <div className={styles.wrapper}>
            <h2 className={styles.title}>Give your company and team the recognition they deserve!</h2>
            <p className={styles.summary}>Awards can be an incredibly powerful asset in helping winners and finalists enhance brand image and reinforce existing sales messages and collateral. Awards can help build deeper team engagement and will absolutely support a positive employer brand identity in any recruitment campaigns. </p>
            {/* <Button to={"/enter"} className={styles.button}>Enter now</Button> */}
          </div>
          <Stats values={allStats} />
        </div>
      </div>
    </section>
  )
}

export default Quickstats