import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import './Loading.module.scss'; // Assuming you store the CSS here

const LoadingDelay = (WrappedComponent, delay = 1200) => {
  return function LoadingDelay(props) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, delay);

      return () => clearTimeout(timer);
    }, []);

    return (
      <div className="transition-container">
        {isLoading && <div className="loading-overlay"><Loading /></div>}
        <div className={`content ${isLoading ? 'content-hidden' : 'content-visible'}`}>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };
};

export default LoadingDelay;
