/* global gtag */ /* <-- Keep to allow React to be aware this exists in the index.html */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "./CookieConsent.module.scss";
import { buttonStyles } from "../Buttons";
import { COOKIE_NAME } from "../../Constants/constants";

const CookieConsent = ({ onConsent, onDeny }) => {
  const userConsentCookie = Cookies.get(`${COOKIE_NAME}_Consent`);
  const defaultConsent =
    userConsentCookie === "true"
      ? true
      : userConsentCookie === "false"
      ? false
      : null;
  const [hasConsent, setHasConsent] = useState(defaultConsent);

  useEffect(() => {
    if (hasConsent === true) {
      Cookies.set(`${COOKIE_NAME}_Consent`, "true", { expires: 30, path: "/" });
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
        ad_personalization: "granted",
        ad_user_data: "granted",
      });
      if (onConsent) onConsent();
    } else if (hasConsent === false) {
      Cookies.set(`${COOKIE_NAME}_Consent`, "false", { expires: 30, path: "/" });
      gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
        ad_personalization: "denied",
        ad_user_data: "denied",
      });
      if (onDeny) onDeny();
    }
  }, [hasConsent, onConsent, onDeny]);

  if (hasConsent !== null) return null;

  return (
    <div className={`${styles.cookie} ${hasConsent === false ? styles.cookie_hidden : ""}`}>
      <div className={styles.cookie__content}>
        <h2 className={styles.cookie__title}>
          Do you consent to our use of cookies?
        </h2>
        <small className={styles.cookie__summary}>
        We use cookies to enhance your browsing experience. By clicking "Accept", you consent to our use of cookies. You can read more in our{" "}
          <a
            href='https://www.bpl-business.com/privacy-policy'
            target='_blank'
            className={`${buttonStyles.btn_link} ${styles.cookie__link}`}
            rel='noreferrer'>
            cookie policy here
          </a>
          .
        </small>
      </div>
      <div className={styles.cookie__actions}>
        <button
          className={`${styles.button} ${buttonStyles.button} ${buttonStyles.button_small} ${buttonStyles.btn_secondary}`}
          onClick={() => setHasConsent(true)}
          aria-label='Allow consent to the use of cookies'>
          Accept
        </button>
        <button
          className={`${styles.button} ${buttonStyles.button} ${buttonStyles.button_small} ${buttonStyles.btn_accent}`}
          onClick={() => setHasConsent(false)}
          aria-label='Deny consent to the use of cookies'>
          Deny
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
