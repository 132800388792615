import React from "react";
import styles from './TimetableCard.module.scss';

function TimetableCard({ item, summary, time }) {
  return (
    <div className={styles.card}>
      <div className={styles.item}>
        {item && <p className={styles.title}>{item}</p>}
        {summary && <p className={styles.summary}>{summary}</p>}
      </div>
      {time && (<div className={styles.time}>{time}</div>)}
    </div>
  );
}

export default TimetableCard;
