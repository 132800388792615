import React from "react";
import styles from './Timetable.module.scss';

function TimetableRow({ item, summary, time }) {
  return (
    <div className={styles.row}>
      <div className={styles.item}>
        {item && <p className={styles.title}>{item}</p>}
        {summary && <p className={styles.summary}>{summary}</p>}
        </div>
      {time && (<div className={styles.time}>{time}</div>)}
    </div>
  );
}

export default TimetableRow;
