import { useState } from "react";
import Modal from "../Modal/Modal";
import styles from "./Judge.module.scss"

const Judge = ({ profiles }) => {
  const [openModal, setOpenModal] = useState(false);

  // Extract the surname and sort by it
  profiles.sort((a, b) => {
    const surnameA = a.name.split(" ").slice(-1)[0]; // Get the last word as the surname
    const surnameB = b.name.split(" ").slice(-1)[0];
    return surnameA.localeCompare(surnameB);
  });

  return (
    <div className={styles.judges__wrapper}>
      <ul className={styles.judges__grid}>
        {profiles.map((item) => (
          <li className={styles.judges__item} key={item.id}>
            <img src={process.env.PUBLIC_URL + item.picture} className={styles.judges__image} alt={`${item.name}`} width='300' />
            <h3 className={styles.judges__name}>{item.name}</h3>

            <Modal title={item.name} content={item.bio} button='Learn more'
              isOpen={openModal === item.id}
              onClose={() => setOpenModal(null)}
            />

            <button className={styles['category-link']} onClick={() => setOpenModal(item.id)}>
              Learn more
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Judge;
