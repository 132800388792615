import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Accordion from "../../components/Accordian/Accordion";
import {items} from "./faq-data"

const Faq = () => {


  return (
    <div>
      <PageTitle>Frequently Asked Questions</PageTitle> 
      <Accordion items={items} />       
    </div>
  );
};

export default Faq;