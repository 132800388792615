import React from "react";
import FinalistsTab from "../components/FinalistsTab/FinalistsTab";
import { data } from "../components/Category/CategoryData";
import PageTitle from '../components/PageTitle/PageTitle'
import styles from '../components/Category/Categories.module.scss'

import { FINALISTS } from "../Constants/constants";

const Finalists = () => {
  return (
    <>
      <PageTitle>Finalists</PageTitle>

    {FINALISTS ? <FinalistsTab data={data} /> : <p className={styles.finalists__subtitle}>To be announced</p> }
    </>

  );
};

export default Finalists;