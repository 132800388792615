import React from "react";
import Button from "../../components/Button";
import styles from "./404.module.scss"
import PageTitle from "../../components/PageTitle/PageTitle";

function LostPage(){

    return(

        <>
    <PageTitle> Page not found</PageTitle>

    <div className={styles.lost__wrapper}>

        <Button to={"/"}> Back to Home </Button>

    </div>

</>

)


};


export default LostPage;