import React from "react";
import styles from "./Holding.module.scss"
import { EVENT_NAME } from "../../Constants/constants";
import logo from "../../logo.svg";

function Holding() {

  return (
    <div className={styles.holding__wrapper}>
      <div className={styles.holding__content__wrapper}>
        <img src={logo} alt="" className={styles.holding__logo} />
        <h1 className={styles.holding__title}> Thanks for Attending the {EVENT_NAME} 2023 </h1>
        <p className={styles.holding__text}> We are busy working on our 2024 site. </p>
      </div>
    </div>
  );
};


export default Holding;