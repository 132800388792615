import React from "react";
import styles from "./Tables.module.scss";
import Button from '../Button/Button'
import { tableData } from "./TableData";
import { EVENT_YEAR } from "../../Constants/constants";

function Tables() {

  const sortedTableData = [...tableData].sort((a, b) => a.order - b.order);

  return (
    <>
      <div id="tickets" className="container">
      <div className={styles.tables__title__container}>
        <h1 className={styles.tables__title}> Book a Table</h1>
        <p className={styles.tables__subtitle}> The Comms National Awards provide a spectacular evening of entertainment, industry networking and celebration. Contact Heather Miles on <span className={styles.tables__span}>+44 (0)7955 313700</span> or email <span className={styles.tables__span}>hmiles@bpl-business.com</span> to book your place now for the channel event of the year! </p>
      </div>

      <div className={styles.tables__wrapper}>
        {sortedTableData.map((table, index) => {
          return (
            <div className={styles.tables__card} key={table.id}>
              <h3 className={styles.tables__type}>{table.name}</h3>
              <div className={styles.tables__price}>&pound;{table.price} <span>+ VAT</span></div>
              <ul className={styles.tables__list}>
                {table.includes.map((item, index) => (
                  <li key={`${table.id}-item-${index}`} className={styles.tables__items}>{item}</li>
                ))}
              </ul>
              <Button className={styles.table__button} to={`mailto:hmiles@bpl-business.com?subject=Comms%20National%20Awards%20Table%20booking%20-%20${table.name}&body=Hello%20Heather,%0D%0A%20%0D%0A%20We%20would%20like%20to%20book%20a%20${table.name}%20for%20the%20Comms%20National%20Awards%20${EVENT_YEAR}`}>Book Table</Button>
            </div>
          )
        })}
      </div>
      </div>
    </>
  );
};

export default Tables;