import React from "react";
import WinnersTab from "../components/WinnersTab/WinnersTab";
import { data } from "../components/Category/CategoryData";
import PageTitle from '../components/PageTitle/PageTitle'
import styles from '../components/Category/Categories.module.scss'

import { WINNERS } from "../Constants/constants";

const Winners = () => {
  return (
    <>
      <PageTitle>Winners</PageTitle>

    {WINNERS ? <WinnersTab data={data} /> : <p className={styles.finalists__subtitle}>To be announced</p> }
    </>

  );
};

export default Winners;