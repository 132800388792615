import {ENTRY_DEADLINE_DM, EVENT_DATE_DM, EVENT_FINALISTS_DATE_DM, EVENT_ENTRIES_OPEN_DATE_DM} from '../../Constants/constants'

export const times = [
  {
    label: "Drinks Reception",
    summary: '',
    time: "19:00",
  },
  {
    label: "Dinner",
    summary: '',
    time: "19:45",
  },
  {
    label: "Awards Ceremony",
    summary: '',
    time: "22:00",
  },
  {
    label: "After Party",
    summary: '',
    time: "23:30",
  },
  {
    label: "Carriages",
    summary: '',
    time: "01:00",
  },
];

export const schedule = [
  {
    label: EVENT_ENTRIES_OPEN_DATE_DM,
    summary: 'Registration Opens',
    time: "",
  },
  {
    label: ENTRY_DEADLINE_DM,
    summary: 'Entries close',
    time: "",
  },
  {
    label: EVENT_FINALISTS_DATE_DM,
    summary: 'Finalists announced',
    time: "",
  },
  {
    label: EVENT_DATE_DM,
    summary: 'Event Day',
    time: "",
  }
]


