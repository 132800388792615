import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import intlTelInput from "intl-tel-input";
import { useFormik } from "formik";
import utilsScript from 'intl-tel-input/build/js/utils.js';
import "intl-tel-input/build/css/intlTelInput.css";
import Modal from "../Modal/Modal";
import PageTitle from "../PageTitle/PageTitle";
import buttonStyles from "../Buttons/Button.module.scss";
import { data } from "../Category";
import { basicSchema } from "./schemas";
import styles from "./Form.module.scss";
import { SITE_URL } from "../../Constants/constants";

function Enquiry() {
  const [csrfToken, setCsrfToken] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState({ text: "", type: "" });
  const [categoryError, setCategoryError] = useState("");
  const [groupedCategories, setGroupedCategories] = useState({
    Reseller: [],
    Vendor: [],
    Business: []
  });
  const itiRef = useRef(null);
  const phoneInputRef = useRef(null);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      jobTitle: "",
      emailAddress: "",
      isPrCompany: false,
      prCompanyName: "",
      contactNumber: "",
      selectedCategories: [],
      terms: false,
    },
    validationSchema: basicSchema,
    onSubmit: async (values, actions) => {
      const hasSelection =
        groupedCategories.Business.length > 0 ||
        groupedCategories.Reseller.length > 0 ||
        groupedCategories.Vendor.length > 0;

      if (!hasSelection) {
        setCategoryError("Please select at least one category.");
        return;
      }

      setCategoryError("");
      try {
        const canBeImploded = (array) => array.join(', ');
        const businessCategories = canBeImploded(groupedCategories.Business);
        const resellerCategories = canBeImploded(groupedCategories.Reseller);
        const vendorCategories = canBeImploded(groupedCategories.Vendor);

        const formData = {
          ...values,
          businessCategories,
          resellerCategories,
          vendorCategories,
          csrfToken,
        };

        const response = await axios.post(`${SITE_URL}/backend/submit.php`, formData, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        if (response.data.message && response.data.type) {
          setSubmitMessage({
            text: response.data.message,
            type: response.data.type,
            uuid: response.data.uuid
          });
          actions.resetForm();
        }
      } catch (error) {
        if (error.response && error.response.data.errors) {
          const serverErrors = error.response.data.errors.reduce((acc, curr) => {
            acc[curr] = curr;
            return acc;
          }, {});
          setErrors(serverErrors);
        } else {
          setSubmitMessage({ text: "An error occurred while submitting the form.", type: "error" });
          console.error("Error submitting the form:", error);
        }
      }
    },
  });

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${SITE_URL}/backend/inc/get_csrf_token.php`, { withCredentials: true });
        if (response.data.csrfToken) {
          setCsrfToken(response.data.csrfToken);
        }
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();

    const setupIntlTelInput = () => {
      if (phoneInputRef.current) {
        itiRef.current = intlTelInput(phoneInputRef.current, {
          utilsScript,
          initialCountry: "gb",
          nationalMode: false,
          autoPlaceholder: "polite",
        });
      }
    };

    setupIntlTelInput();

    return () => {
      if (itiRef.current) {
        itiRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (values.contactNumber && itiRef.current) {
      const countryData = itiRef.current.getSelectedCountryData();
      const dialCode = countryData.dialCode;
      let directValue = values.contactNumber.replace(/\D/g, ''); // Strip non-digits

      // Remove leading zero if present
      if (directValue.startsWith('0')) {
        directValue = directValue.substring(1);
      }

      // Prevent duplicating the dial code
      if (!directValue.startsWith(dialCode)) {
        directValue = `+${dialCode}${directValue}`;
      } else {
        directValue = `+${directValue}`;
      }

      setFieldValue("contactNumber", directValue);
    }
  }, [values.contactNumber, setFieldValue]);

  const handleCategoryInput = (e, topic, category) => handleCategoryChange(e, topic, category.name);

  const handleCategoryChange = (e, topic, categoryName) => {
    const { checked } = e.target;
    setGroupedCategories(prev => {
      const currentCategories = prev[topic] || [];
      return {
        ...prev,
        [topic]: checked ? [...currentCategories, categoryName] : currentCategories.filter(name => name !== categoryName)
      };
    });
  };

  const handleContactNumberBlur = (event) => {
    handleBlur(event);
    if (itiRef.current) {
      const countryData = itiRef.current.getSelectedCountryData();
      const dialCode = countryData.dialCode;
      let directValue = event.target.value.replace(/\D/g, ''); // Strip non-digits

      // Remove leading zero if present
      if (directValue.startsWith('0')) {
        directValue = directValue.substring(1);
      }

      // Prevent duplicating the dial code
      if (!directValue.startsWith(dialCode)) {
        directValue = `+${dialCode}${directValue}`;
      } else {
        directValue = `+${directValue}`;
      }

      setFieldValue("contactNumber", directValue);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTermsChange = (event) => setFieldValue("terms", event.target.checked);

  const navigate = useNavigate();

  if (submitMessage.type === 'success') {
    navigate(`/entry/${submitMessage.uuid}`);
    return null; // or a loading indicator until redirect completes
  }


  return (
    <>

      <div>
        <PageTitle>Enter Now</PageTitle>
        <form onSubmit={handleSubmit} className={`${styles.form}`}>
          <div className={styles.form__wrapper}>
            <div>
              <div className={styles.form__one}>
                <div className={styles.form__group}>
                  <div className={styles.form__item}>
                    <label htmlFor='firstName'>First name</label>
                    <input
                      id='firstName'
                      name='firstName'
                      type='text'
                      placeholder='Enter your first name'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.firstName && touched.firstName ? styles.input_error : ""
                      }
                      required
                    />
                    {errors.firstName && touched.firstName && (
                      <em className={styles.message_error}>{errors.firstName}</em>
                    )}
                  </div>
                  <div className={styles.form__item}>
                    <label htmlFor='lastName'>Last name</label>
                    <input
                      id='lastName'
                      name='lastName'
                      type='text'
                      placeholder='Enter your last name'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.lastName && touched.lastName ? styles.input_error : ""
                      }
                      required
                    />
                    {errors.lastName && touched.lastName && (
                      <em className={styles.message_error}>{errors.lastName}</em>
                    )}
                  </div>
                </div>

                <div className={styles.form__item}>
                  <label htmlFor='jobTitle'>Job Title</label>
                  <input
                    id='jobTitle'
                    name='jobTitle'
                    type='text'
                    placeholder='Enter your job title'
                    value={values.jobTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.jobTitle && touched.jobTitle
                        ? styles.input_error
                        : ""
                    }
                    required
                  />
                  {errors.jobTitle && touched.jobTitle && (
                    <em className={styles.message_error}>{errors.jobTitle}</em>
                  )}
                </div>

                <div className={styles.form__item}>
                  <label htmlFor='companyName'>Company name</label>
                  <input
                    id='companyName'
                    name='companyName'
                    type='text'
                    placeholder='Enter your company name'
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.companyName && touched.companyName
                        ? styles.input_error
                        : ""
                    }
                    required
                  />
                  {errors.companyName && touched.companyName && (
                    <em className={styles.message_error}>{errors.companyName}</em>
                  )}
                </div>

                <div className={styles.form__item}>
                  <div className={`${styles.form__checkbox}`}>
                    <input
                      id='isPrCompany'
                      name='isPrCompany'
                      type='checkbox'
                      checked={values.isPrCompany}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`input_checkbox ${errors.isPrCompany && touched.isPrCompany ? styles.input_error : ""}`}
                    />
                    <label htmlFor='isPrCompany'>Are you a PR company entering on behalf of another company?</label>
                  </div>
                </div>

                {values.isPrCompany && (
                  <div className={styles.form__item}>
                    <label htmlFor='prCompanyName'>PR Company name</label>
                    <input
                      id='prCompanyName'
                      name='prCompanyName'
                      type='text'
                      placeholder='Enter your PR company name'
                      value={values.prCompanyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.prCompanyName && touched.prCompanyName ? styles.input_error : ""}
                      required
                    />
                    {errors.prCompanyName && touched.prCompanyName && (
                      <em className={styles.message_error}>{errors.prCompanyName}</em>
                    )}
                  </div>
                )}

                <div className={styles.form__item}>
                  <label htmlFor='emailAddress'>Email Address</label>
                  <input
                    id='emailAddress'
                    name='emailAddress'
                    type='email'
                    placeholder='Enter your email'
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.emailAddress && touched.emailAddress
                        ? styles.input_error
                        : ""
                    }
                    required
                  />
                  {errors.emailAddress && touched.emailAddress && (
                    <em className={styles.message_error}>{errors.emailAddress}</em>
                  )}
                </div>

                <div className={styles.form__item}>
                  <label htmlFor='contactNumber'>Contact Number</label>
                  <input
                    ref={phoneInputRef}
                    id='contactNumber'
                    name='contactNumber'
                    type='tel'
                    placeholder='Enter your contact number'
                    value={values.contactNumber}
                    onChange={handleChange}
                    onBlur={handleContactNumberBlur}
                    className={
                      errors.contactNumber && touched.contactNumber
                        ? styles.input_error
                        : ""
                    }
                    required
                  />
                  {errors.contactNumber && touched.contactNumber && (
                    <em className={styles.message_error}>{errors.contactNumber}</em>
                  )}
                </div>

                <div className={styles.form__item}>
                  <div className={`${styles.form__checkbox}`}>
                    <input
                      id='terms'
                      name='terms'
                      type='checkbox'
                      checked={values.terms}
                      onChange={handleTermsChange}
                      onBlur={handleBlur}
                      className={`input_checkbox ${errors.terms && touched.terms ? styles.input_error : ""}`}
                      required
                    />
                    <div>
                      <label htmlFor='terms'>Terms and conditions</label>
                      <small className={styles.terms__description}>
                        By submitting this form, you confirm that you have read and
                        agree to the {' '}
                        <button
                          onClick={handleOpenModal}
                          aria-label={`Open terms and conditions`}
                          className={`${buttonStyles.button_link} ${buttonStyles.btn_link_secondary}`}>
                          Terms and Conditions
                        </button>
                      </small>
                    </div>
                  </div>
                  {errors.terms && touched.terms && (
                    <em className={styles.message_error}>{errors.terms}</em>
                  )}
                </div>

                <input type='hidden' name='csrfToken' value={csrfToken} />
                <button
                  disabled={isSubmitting}
                  className={`${buttonStyles.button} ${buttonStyles.btn_primary} ${isSubmitting ? "input_submitting" : ""}`}
                  type='submit'>
                  Submit
                </button>
              </div>
            </div>

            <div className={styles.form__two}>
              {categoryError && <div className={styles.message_error}>{categoryError}</div>}

              {data.map((item, index) => (
                <div key={`${item.id}-${Math.random()}`}>
                  <div className="topic__title">
                    <h2 className={styles.category__title}> {item.topic} </h2>
                  </div>

                  {item.categories.map((category) => (
                    <div key={category.id}>
                      <div className={styles.category__choice}>
                        <input
                          id={category.id}
                          type="checkbox"
                          checked={(groupedCategories[item.topic] || []).includes(category.name)}
                          onChange={(e) => handleCategoryInput(e, item.topic, category)}
                          className={styles.category__checkbox}
                        />
                        <div className="category__labels">
                          <label className={styles.category__section} htmlFor={category.id}>{category.subcategory}</label>
                          <label className={styles.category__label} htmlFor={category.id}>{category.name}</label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </form>

        <Modal
          title={"Terms & Conditions"}
          terms={[
            `By completing and submitting this form, you agree that the provided details may be used by BPL Business Media Group to communicate updates regarding the Comms National Awards and to follow up with you regarding potential submission of entries to our awards process.`,
            <p>Your information will be handled in accordance with our <a href={`https://www.bpl-business.com/privacy-policy`} target="_blank" rel="noreferrer">{' '}privacy policy{' '}</a> and will not be shared with third parties without your consent.</p>,
          ]}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      </div>
    </>
  );
}

export default Enquiry;
