import React from "react";
import TimetableRow from "./TimetableRow";
import TimetableCard from "./TimetableCard";
import styles from './Timetable.module.scss'


function Timetable({ times, schedule }) {
  return (
    <div>
      {schedule && (
        <div className={`${styles.schedule}`}>
          {schedule.map((item) => (
            <TimetableCard item={item.label} time={item.time} summary={item.summary} key={item.label} />
          ))}
        </div>
        )}
      
      
      {times && (
        <div className={styles.timetable}>
          <div className={styles.night__title}> On the night…  </div>
          {times.map((item) => (
            <TimetableRow item={item.label} time={item.time} summary={item.summary} key={item.label} />
          ))}
          
        </div>)}
        
    </div>
  );
}

export default Timetable;
