import React, { Fragment, useEffect } from "react";
import styles from "./Modal.module.scss";
import Button from "../Button";

function Modal(props) {
  // Use the props to determine if the modal is open
  const isOpen = props.isOpen;

  // useEffect hook to handle the body's class update
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scrolling");
    } else {
      document.body.classList.remove("no-scrolling");
    }


    // Cleanup function to ensure the class is removed when the component is unmounted
    return () => {
      document.body.classList.remove("no-scrolling");
    };
  }, [isOpen]);

  // Define a function to handle clicks on the background outside the modal
  function handleBackgroundClick(e) {
    // If the target of the click is the same as the container div, close the modal
    if (e.target === e.currentTarget && props.onClose) {
      props.onClose();
    }
  }

  return (
    <div>
      {/* Render the modal based on the isOpen prop */}
      {isOpen && (
        <div className={`${styles['modal']}`} onClick={handleBackgroundClick}>
          <div className={`${styles['modal__content']}`}>
            {/* Render a close button for the modal */}
            <span className={`${styles['close']}`} onClick={props.onClose}>
              &times;
            </span>
            {/* Render the title of the modal */}
            <h2 className={`${styles['modal__title']}`}>{props.title}</h2>
            <div className={`${styles['modal__body']}`}>
              {/* If there is a videoSrc prop, render the iframe */}

              {props.videoSrc && (
                <div
                  className={`${styles['modal__video']}`}
                  style={{ paddingTop: "56.25%", position: "relative" }}>
                  <iframe
                    title={props.title}
                    src={props.videoSrc}
                    frameBorder='0'
                    allow='autoplay; fullscreen'
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}></iframe>
                </div>
              )}

              {/* If there is an image prop, render an image element */}
              {props.image && (
                <div className={`${styles['modal__image']}`}>
                  <img src={props.image} alt={props.title} />
                </div>
              )}

              {Array.isArray(props.terms) ? (
                <div>
                  {props.terms.map((item, index) => (
                    <Fragment key={index} className={`${styles['modal__item']}`}>
                      {/* If the item is a string, render it as a paragraph */}
                      {typeof item === 'string' ? (
                        <span className={`${styles['modal__paragraph']}`}>{item}</span>
                      ) : (
                        // Otherwise, render it directly
                        item
                      )}
                    </Fragment>
                  ))}
                </div>
              ) : (
                // If the content is not an array, render it as a single paragraph
                <>{props.content && (<p className={`${styles['modal__paragraph']}`}>{props.content}</p>)}</>
              )}

            </div>
            {/* If there is an actionlink prop, render a link button */}
            {props.actionlink && (
              <div className={`${styles['modal__actions']}`}>
                <Button to={props.actionlink}>{props.actiontext}</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
