import React from 'react'
import Cards from '../../components/Cards';
import styles from './Panels.module.scss'
import Button from '../../components/Button/Button';

const Panels = () => {
  return (
    <section className={styles.panels}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.panel_one}>
            <Cards className={`${styles.judges} ${styles.cards}`}>
              <h2 className={styles.panel_title}>Judges</h2>
              <p className={styles.panel_summary}>Once again, we have assembled a top-quality judging panel for the 2024 Comms National Awards who have in
                depth knowledge of the ICT industry and broad experience across the channel spectrum.</p>
                <Button to={"/judges"}>Learn More</Button>
            </Cards>
          </div>
          <div className={styles.panel_two}>
            <Cards className={`${styles.faq} ${styles.cards}`}>
              <h2 className={styles.panel_title}>FAQ</h2>
              <p className={styles.panel_summary}>Have a question about how to submit an entry? Perhaps you want to know how many categories you can enter in.
                Our most commonly answered questions can be found here.</p>
                <Button to={"/faq"}>Visit FAQ</Button>
            </Cards>
          </div>
          <div className={styles.panel_three}>
            <Cards className={`${styles.entry_tips} ${styles.cards}`}>
              <h2 className={styles.panel_title}>Entry Tips</h2>
              <p className={styles.panel_summary}>How to put together a top notch entry for the Comms National Awards. Visit our FAQ.</p>
              <Button to={"/entry-tips"}> Learn More</Button>
            </Cards>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Panels