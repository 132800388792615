import React from "react";
import styles from "./PlayButton.module.scss";

const PlayButton = ({ isPlaying, handleTogglePlay }) => {
  return (
    <button
      className={`${styles.play} ${isPlaying ? styles.isPlaying : ""}`}
      onClick={handleTogglePlay}
      aria-label={isPlaying ? 'Pause the video' : 'Play the video'}>
      {isPlaying ? (
        <i className={`fa-solid fa-pause ${styles.play__icon}`}></i>
      ) : (
        <i className={`fa-solid fa-play ${styles.play__icon}`}></i>
      )}
    </button>
  );
};

export default PlayButton;
