import * as yup from "yup";

// Define validation regex rules
const stringOnlyRules = /^[a-zA-Z\s-']*$/;
const mixedStringRules = /^[a-zA-Z0-9\s-'.]*$/;

export const basicSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Please enter a valid email")
    .required("Email address is required"),
  firstName: yup
    .string()
    .min(2, "First name must be at least 2 characters")
    .matches(stringOnlyRules, { message: "Please enter a valid first name" })
    .required("First name is required"),
  lastName: yup
    .string()
    .min(2, "Last name must be at least 2 characters")
    .matches(stringOnlyRules, { message: "Please enter a valid last name" })
    .required("Last name is required"),
    isPrCompany: yup.bool(),
  prCompanyName: yup
    .string()
    .nullable() // Mark as nullable to allow empty values
    .when("isPrCompany", {
      is: true,
      then: (schema) => schema
        .required("PR company name is required")
        .matches(mixedStringRules, { message: "Please enter a valid PR company name" }),
    }),
  companyName: yup
    .string()
    .min(2, "Company name must be at least 2 characters")
    .matches(mixedStringRules, { message: "Please enter a valid company name" })
    .required("Company name is required"),
  jobTitle: yup
    .string()
    .min(2, "Job title must be at least 2 characters")
    .matches(mixedStringRules, { message: "Please enter a valid job title" })
    .required("Job title is required"),
  contactNumber: yup.string().required("Contact number is required"),
  terms: yup
    .bool()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Terms and conditions are required"),
});
