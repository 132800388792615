import React from 'react'
import SocialMediaItem from './SocialMediaItem'

const SocialMedia = ({items}) => {
  return (
    <div>
      {items.map((item) => {
        return (
          <SocialMediaItem key={item.key} item={item} />
        )
      })}
    </div>
  )
}

export default SocialMedia