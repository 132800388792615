import React from 'react'
import styles from './PageTitle.module.scss'

export default function PageTitle({children }) {
  return (
    <>
      {children && (
        <div className={styles['title-wrapper']}>
          <h1 className={styles.title}>{children}</h1>
        </div>
      )}
    </>
  )
}
