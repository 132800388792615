import React from "react";
import styles from "./Venue.module.scss";
import { EVENT_ADDRESS, EVENT_VENUE, ENTRY_DEADLINE, EVENT_DATE } from "../../Constants/constants";


const Venue = ({ title, className }) => {
  return (
    <section id='venue' className={styles.venue}>
      <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.media}>
          <div className={styles.media_first}>
            <img src="./img/host-2.jpg" alt="" />
          </div>
          <div className={styles.media_secondary}>
            <img src="./img/room-2.jpg" alt="" />
          </div>
        </div>

        <div className={styles.content}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <p>
          The {EVENT_VENUE} is superbly located in the heart of London. With local access to transport and luxurious suites, regardless whether you join us from near or far, we know you'll be enjoying a luxury event.
          </p>

          <p>
            {/* <i className={`fas fa-globe-europe`}></i> */}
            <strong>Venue:</strong> {EVENT_ADDRESS}
          </p>
          <p>
            {/* <i className={`fas fa-globe-europe`}></i> */}
            <strong>Entry Deadline</strong>: {ENTRY_DEADLINE}
          </p>

          <p>
            {/* <i className={`fas fa-globe-europe`}></i> */}
            <strong>Awards Night: </strong>: {EVENT_DATE}
          </p>

          <p>
            {/* <i className={`fas fa-globe-europe`}></i> */}
            <strong>Dress Code</strong>: Black Tie and Posh Frocks
          </p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Venue;
