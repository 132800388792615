import React from "react";
import FooterNavigation from "../Navigation/FooterNavigation";
import { PrimaryFooterMenu, SecondaryFooterMenu, EventFooterMenu } from "../Navigation/NavigationData";
import { accounts } from "../SocialMedia";
import styles from "./Footer.module.scss";
import SocialMedia from "../SocialMedia";

const Footer = () => {
  const DATE = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.footer__details}>
            <img src={`${process.env.PUBLIC_URL}/img/bpl.png`} alt="BPL logo" className={styles.footer__branding} />
            <p className={styles.footer__summary}>
              BPL Business Media Ltd is the leading media and event organisation for the UK telecommunications and ICT channels.
            </p>
            <SocialMedia items={accounts} />
          </div>
          <FooterNavigation title={"Helpful"} items={PrimaryFooterMenu} />
          <FooterNavigation title={"Support"} items={SecondaryFooterMenu} />
          <div>
            <FooterNavigation title={"Other media"} items={EventFooterMenu} />
            <a  target="blank" href="https://www.showtimephotobooth.co.uk/"> <img src={`${process.env.PUBLIC_URL}/img/showtime-logo.png`}  alt="Showtime Logo" className={styles.footer__logo} /> </a>
          </div>
        </div>
      </div>
      <small className={styles.footer__copyright}>
        &copy; {DATE} BPL Business Media Ltd. All rights reserved.
      </small>
    </footer>
  );
};

export default Footer;
