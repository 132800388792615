import React from "react";
import { Link } from "react-router-dom";
import styles from './FooterNavigation.module.scss'

function FooterNavigation({ title, items }) {
  return (
    <>
      {items ? (<nav className={styles.nav}>
        {title && ( <h3 className={styles.nav__title}>{title}</h3> )}
        <ul className={styles.nav__list}>
          {items && items.map((item, index) => (
            <li className={styles.nav__item} key={item.id}>{item && (<Link className={styles.nav__link} to={item.path}>{item.label}</Link>)}</li>
          ))}
        </ul>
      </nav>) : 'Menu missing'}</>)
}

export default FooterNavigation;
