import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Breadcrumbs.module.scss'

function Breadcrumbs() {
  return (
    <nav aria-label={'breadcrumb'} className={styles.breadcrumbs}>
      <ul className={styles.breadcrumb__list}>
        <li className={styles.breadcrumb__item}>
          <Link to={'/enter'} className={styles.breadcrumb__link}>Go back to the enter page</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Breadcrumbs;
