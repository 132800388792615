import React, { useEffect, useState } from 'react'
import styles from './CategoryCards.module.scss'
import Button from '../Button';

const CategoryCards = ({ topics, title }) => {

  const [sortedTopic, setSortedTopic] = useState([]);

  useEffect(() => {
    // Sort the entire topics array based on the 'order' property of each topic
    const sorted = [...topics].sort((topicA, topicB) => topicA.order - topicB.order);

    setSortedTopic(sorted);
  }, [topics]);


  return (
    <section id='categories' className={styles.category__cards}>
      <div className="container">
      {title && (<h2 className={styles.title}>{title}</h2>)}
        <div className={styles.wrapper}>
          {sortedTopic.map((item) => (
            <div className={styles.category__card} key={item.topic}>
              {item.topic && (<h3 className={styles.category__card__title}>{item.topic}</h3>)}
              {item.summary && (<p className={styles.category__card__summary}>{item.summary}</p>)}
              
              <Button className={styles.category__button} to={"/categories"}>View Categories</Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CategoryCards