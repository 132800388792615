import React from 'react'
import Button, {buttonStyles} from '../Button'
import styles from './Cards.module.scss'

const Cards = ({title, body, children, link, className}) => {
  return (
    <div className={`${styles.card} ${className}`}>
      {title && (<h2 className='title'>{title}</h2>)}
      {body && (<p className='body'>{body}</p>)}
      {children}
      {link && (<Button className={buttonStyles.btn_primary} to={'#'}>{title}</Button>)}
    </div>
  )
}

export default Cards