import React from "react";
import styles from './Contacts.module.scss';

const Contact = ({ contacts }) => {
  const formatContactNumber = (contactNumber) => {
    return contactNumber.slice(3, contactNumber.length).replace(/^/, "+44 (0)");
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.contact_list}>
        {contacts.map(({ id, name, picture, role, contactNumber, email }) => (
          <li key={id} className={styles.contact_item}>
            <h3 className={styles.contact_role}>{role}</h3>
            <img src={picture} alt={`${name}`} className={styles.contact_photo} width='300' />
            <h4 className={styles.contact_name}>{name}</h4>
            <p className={styles.contact_details}>
              <a href={`mailto:${email}`} className={styles.contact_email}>{email}</a>
              <a href={`tel:${contactNumber}`} className={styles.contact_number}>
                {formatContactNumber(contactNumber)}
              </a>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contact;
