import { EVENT_NAME } from "../../Constants/constants";
import Timetable from "../Timetable"
import { times, schedule } from "../Timetable/Times"

import "./agenda.scss"; // Import your CSS file

function Agenda() {

  return (
    <section className="agenda" id="agenda">
      <h2 className="agenda__title">Timings</h2>
      <p className="agenda__subtitle">The {EVENT_NAME} has all the makings for a brilliant event to celebrate your teams successes.</p>

      <Timetable schedule={schedule} times={times} />
    </section>
  )


}

export default Agenda;